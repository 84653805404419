import { template as template_cd46bd5c4d4b4c708c41746a9e3160d3 } from "@ember/template-compiler";
const WelcomeHeader = template_cd46bd5c4d4b4c708c41746a9e3160d3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
