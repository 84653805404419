import { template as template_5162346d884140d3b2c06805b72e3d47 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5162346d884140d3b2c06805b72e3d47(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
